import logo from './logo.svg';
import './App.css';
import Books from './Books.js'
import React, { useState, Suspense, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HTMLFlipBook from "react-pageflip";
import useFetch from './utils/useFetch';
import {Link} from 'react-router-dom';
import Loader from './utils/Loader.js'
import { BackgroundImage } from 'react-image-and-background-image-fade'
import { formatDate } from "./utils/formatDate.js";

function About() {
  const posts = useFetch('https://admin.magasinetkote.no/wp-json/wp/v2/pages/2102');
  console.log(posts)
  return (
    <div className='about'>
      {posts &&
                <div className='content' dangerouslySetInnerHTML={{__html: posts.content.rendered}} />
      }
   </div>
  );
}

export default About;
