import logo from './logo.svg';
import './App.css';
import Books from './Books.js'
import React, { useState, Suspense, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HTMLFlipBook from "react-pageflip";
import useFetch from './utils/useFetch';
import {useParams} from 'react-router-dom';
import Loader from "./utils/Loader.js";
import { formatDate } from "./utils/formatDate.js";
function NewsSingle() {
    const [page, setPage] = useState(1);
    const params = useParams();
    console.log(params)
    const posts = useFetch('https://admin.magasinetkote.no/wp-json/wp/v2/nyheter?slug='+params.id.toString());
    console.log('https://admin.magasinetkote.no/wp-json/wp/v2/nyheter?slug='+params.id.toString())

  return (
    <React.Fragment>
    <div className='archive single'>
        {posts && posts.map((post, index) => (
            <div className='post single'>
              <div className='meta'>
              <div className="title" dangerouslySetInnerHTML={{__html: post.title.rendered}} />
              <div className="date">
                Publisert {formatDate(post.date)} av {post.x_author}
              </div>
              </div>
                <div className='content' dangerouslySetInnerHTML={{__html: post.content.rendered}} />
            </div>
        ))}
        
   </div>
   <React.Fragment>
   {!posts &&
    <Loader />
   }
   </React.Fragment></React.Fragment>
  );
}

export default NewsSingle;
