import logo from './logo.svg';
import './App.css';
import Books from './Books.js'
import React, { useState, Suspense, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HTMLFlipBook from "react-pageflip";
import useFetch from './utils/useFetch';
import {Link} from 'react-router-dom';
import Loader from './utils/Loader.js'
import { BackgroundImage } from 'react-image-and-background-image-fade'
import { formatDate } from "./utils/formatDate.js";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
function Archive() {
  const [page, setPage] = useState(1);
  const fetchData = (setItems, items) => {
  axios
    .get(`https://admin.magasinetkote.no/wp-json/wp/v2/arkiv?per_page=25&_embed&page=`+page)
    .then((res) => {
      setItems([...items, ...res.data]);
      setPage(page+1);
    });
  };
  
  const refresh = (setItems) => {};
  const [items, setItems] = React.useState([]);
    React.useEffect(()=>{
      fetchData(setItems,items)
      console.log(items)
    },[])
  return (
    <React.Fragment>
      <InfiniteScroll
     dataLength={items.length} //This is important field to render the next data
     next={() => {
       fetchData(setItems, items);
     }}
     hasMore={true}
   >
    <div className='archive'>
        {items && items.map((post, index) => (
          <Link to={"/arkiv/"+post.slug}>
            <div className='post'>
            <div className='title' dangerouslySetInnerHTML={{__html: post.title.rendered}} />
            <div className='date' dangerouslySetInnerHTML={{__html: formatDate(post.date)}} />
            </div>
            </Link>
        ))
        }
    </div>
   </InfiniteScroll>
   <React.Fragment>
    {items.length == 0 &&
    <Loader />
    }
    </React.Fragment>
   </React.Fragment>
  );
}

export default Archive;
