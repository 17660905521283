import logo from './logo.svg';
import './App.css';
import Books from './Books.js'
import React, { useState, Suspense, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HTMLFlipBook from "react-pageflip";
import useFetch from './utils/useFetch';
import {Link} from 'react-router-dom';
import Loader from './utils/Loader.js'
import { BackgroundImage } from 'react-image-and-background-image-fade'
import { formatDate } from "./utils/formatDate.js";

function News() {
  const [page, setPage] = useState(1);
  const posts = useFetch('https://admin.magasinetkote.no/wp-json/wp/v2/nyheter?per_page=20&_embed&page='+page.toString());
  const LoadMore = async() => {
    setPage(page+1);
    const posts = useFetch('https://admin.magasinetkote.no/wp-json/wp/v2/nyheter?per_page=20&_embed&page='+page.toString());
  }
return (
  <React.Fragment>
  <div className='news'>
      {posts && posts.map((post, index) => (
          <div className='post'>
                      <Link to={"/nyheter/"+post.slug}>
                      <BackgroundImage
      src={post.x_featured_media_large}

      lazyLoad
      wrapperClassName="thumb"
    >
      </BackgroundImage>
          <h1 dangerouslySetInnerHTML={{__html: post.title.rendered}} />
          <span className="time" dangerouslySetInnerHTML={{__html: formatDate(post.date)}} />
          </Link>
          </div>
          
      ))}
         </div>
         <React.Fragment>
         {!posts &&
          <Loader />
          }
          </React.Fragment>
         </React.Fragment>
);
}

export default News;
