import logo from './logo.svg';
import './App.css';
import Books from './Books.js'
import React, { useState, Suspense } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HTMLFlipBook from "react-pageflip";
import Bar from "./Bar.js";
import Book from "./Book.js";
import Archive from "./Archive.js";
import About from "./About.js";
import News from "./News.js";
import Publications from "./Publikasjoner";
import ArchiveSingle from "./Archive_Single.js";
import NewsSingle from "./NewsSingle.js";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ScrollToTop from "./utils/scrollToTop.js"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useLocation,
  Navigate
} from "react-router-dom";
function NavigateWithParams({ to, ...rest }) {
  const params = useParams();
  let toValue;
  if (typeof to === 'function') {
    toValue = to(params);
  } else {
    toValue = to;
  }
  return <Navigate to={toValue} {...rest} />
}
function App() {
  const location = useLocation();
  return (
    <div className="App">
    <Bar />
    <TransitionGroup component={null}>
    <CSSTransition key={location.key} classNames="fade" timeout={1000} onEntered={() => window.scrollTo(0, 0)}>
    <Routes>
    <Route
        path="/"
        element={<Navigate to="/publikasjoner" replace />}
    />
    <Route exact path="/publikasjoner" element={<Publications/>}/>
    <Route exact path="/publikasjoner/:id" element={<Book/>}/>
    <Route exact path="/book" element={<Book/>}/>
    <Route exact path="/om-oss" element={<About/>}/>
    <Route exact path="/nyheter" element={<News/>}/>
    <Route exact path="/nyheter/:id" element={<NewsSingle/>}/>
    <Route exact path="/arkiv" element={<Archive/>}/>
    <Route exact path="/arkiv/:id" element={<ArchiveSingle/>}/>
    <Route path='/artikler/:id/:id2/:id3/:title' element={<NavigateWithParams 
    to={params => `/arkiv/${params.id}-${params.id2}-${params.id3}-${params.title}`}
    replace />}/>
    </Routes>
    </CSSTransition>
    </TransitionGroup>
        </div>
  );
}

export default App;
