import logo from './logo.svg';
import './App.css';
import Books from './Books.js'
import React, { useState, Suspense, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HTMLFlipBook from "react-pageflip";
import Logo from "./graphics/logo.svg"
import { NavLink, useLocation } from "react-router-dom";
import { SocialIcon } from 'react-social-icons';

function Bar() {
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const openClass = isOpen ? "open":"closed";
  function openMenu() {
    setOpen(!isOpen)
  }
  useEffect(() => {
    setOpen(false);
  }, [location]);
  return (
    <div className="bar">
        <div className={"menu "+openClass}>
          <div className="expand_button" onClick={() => openMenu()}>
          <div className={"arrow down "+openClass}></div>
          </div>
          <div className='left'>
            <div className='item'>
            <NavLink
            to="/publikasjoner">
            <img src={Logo}></img>
            </NavLink>
            </div>
            </div>
            <div className='center'>
            <div class='item'><NavLink
            to="/publikasjoner" activeClassName="active">
            Publikasjoner
          </NavLink></div>
            <div class='item'><NavLink
            to="nyheter" activeClassName="active">
            Nyheter
          </NavLink></div>
            <div class='item'><NavLink
            to="arkiv" activeClassName="active">
            Arkiv
          </NavLink></div>
            <div class='item'><NavLink
            to="om-oss" activeClassName="active">
            Om Oss
          </NavLink></div>
          </div>
          <div className='right'>
            <div className='item'>
              <SocialIcon bgColor={"none"} fgColor={"black"} url="https://www.facebook.com/MagasinetKOTE" />
            </div>
            <div className='item'>
              <SocialIcon bgColor={"none"} fgColor={"black"} url="https://www.instagram.com/magasinetkote/" />
            </div>
          </div>
        </div>
    </div>
  );
}

export default Bar;
