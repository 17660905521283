import logo from './logo.svg';
import './App.css';
import Books from './Books.js'
import React, { useState, Suspense, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HTMLFlipBook from "react-pageflip";
import useFetch from './utils/useFetch';
import {useParams} from 'react-router-dom';
import Loader from "./utils/Loader.js"
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Download from "./graphics/download.svg"
import FullOn from "./graphics/fullscreen_on.svg"
import FullOff from "./graphics/fullscreen_off.svg"
import { useMediaQuery } from 'react-responsive'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function Book() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const handle = useFullScreenHandle();
  const [fullscreenBool, setFullscreen] = useState(false);
  const params = useParams();
  const posts = useFetch('https://admin.magasinetkote.no/wp-json/wp/v2/pdf?slug='+params.id.toString());
  const book = useRef();
  const bookContainer = useRef();
  const [bookWidth, setWidth] = useState(0);
  const [bookHeight, setHeight] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [viewportHeight, setviewportheight] = useState(1000);
  const [heightRatio, setheightRatio] = useState(1000);
  const [loaded, setLoaded] = useState(false);
  
  useLayoutEffect(() => {
    setWidth(bookContainer.current.clientWidth);
    setHeight(bookContainer.current.clientHeight);
  });
  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
    console.log(pdf.numPages);
    getSize(pdf);
    setLoaded(true);
  }
  const onFlip = useCallback((e) => {
    setPageNumber(e.data);
  }, []);
  const getSize = async(file) => {
    const page = await file.getPage(1);
    const viewport = await page.getViewport();
    const viewportHeight_t = viewport.viewBox[3];
    const viewportWidth_t = viewport.viewBox[2];
    const heightRatio = (viewportHeight_t / viewportWidth_t);
    const widthRatio = (viewportWidth_t/viewportHeight_t);
    setheightRatio(heightRatio);
    setviewportheight((bookWidth/2)*heightRatio);
  }
  useEffect(()=>{
    document.addEventListener('keydown', (event) => {
      if (event.key === "ArrowLeft") {
            book.current.pageFlip().flipPrev({corner: ['top', 'bottom']});
            return false;
      } else if (event.key === "ArrowRight") {
            book.current.pageFlip().flipNext({corner: ['top', 'bottom']});
            return false;
      }
    })
  }, [])
  const reportChange = useCallback((state, handle) => {
    var bh = (bookWidth)*heightRatio;
    var wh = window.innerHeight;
    var scale = wh/bh;
    setFullscreen(handle.active);
    console.log(scale)

  });
  return (
    <React.Fragment>
      <div className='book_wrap'>
      <div className='sub_nav'>
      <div className='item'><img src={Download} /> Last Ned</div>
      <div className='item' onClick={handle.enter}><img src={FullOn} /> Fullskjerm</div>
      </div>

      <FullScreen handle={handle} onChange={reportChange}>
    <React.Fragment>
      <div className='book_outer' style={{height: viewportHeight}}>
    <div className={"book " + loaded} ref={bookContainer}>
    {posts && posts.map((post, index) => (
      <React.Fragment>
    {pageNumber != numPages-1 &&
    <div className="next arrow" onClick={() =>
                    book.current.pageFlip().flipNext({corner: ['top', 'bottom']})}></div>
    }
    {pageNumber != 0 &&
    <div className="prev arrow" onClick={() =>
                    book.current.pageFlip().flipPrev({corner: ['top', 'bottom']})}></div>
    }
      <div className='book_inner' style={{"height":viewportHeight*2}}>
      <Document file={post.acf.pdf_file} onLoadSuccess={onDocumentLoadSuccess} renderMode="canvas" loading={null}>
      <HTMLFlipBook onFlip={onFlip} useMouseEvents={isTabletOrMobile} ref={book} flippingTime={600} drawShadow={isTabletOrMobile} showCover={true} width={bookWidth} height={viewportHeight*2}>
      {Array.apply(null, Array(numPages))
    .map((x, i)=>i+1)
    .map(page => <div id={"p"+page} class="paper"><Suspense fallback={<Loader />}><Page width={bookWidth} className={page % 2 == 0 ? "back" : "front"} pageNumber={page}/></Suspense></div>)}
      </HTMLFlipBook>
      </Document>
    </div>
    </React.Fragment>
    ))}
    </div>
    </div>
   </React.Fragment>
   </FullScreen>
   </div>
   {!loaded &&
   <Loader />
   }
   </React.Fragment>
  );
}

export default Book;
