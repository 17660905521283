import logo from './logo.svg';
import './App.css';
import Books from './Books.js'
import React, { useState, Suspense, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import HTMLFlipBook from "react-pageflip";
import useFetch from './utils/useFetch';
import {Link} from 'react-router-dom';
import Loader from './utils/Loader.js'
import Book from './Book.js'
import { BackgroundImage } from 'react-image-and-background-image-fade'

function Publications() {
    const [page, setPage] = useState(1);
    const posts = useFetch('https://admin.magasinetkote.no/wp-json/wp/v2/PDF?per_page=100&_embed&page='+page.toString());
  return (
    <React.Fragment>
    <div className='publications'>
        {posts && posts.map((post, index) => (
            <div className='post'>
                        <Link to={"/publikasjoner/"+post.slug}>
                        <BackgroundImage
        src={post._embedded['wp:featuredmedia']['0'].x_featured_media_large}

        lazyLoad
        wrapperClassName="thumb"
      >
                     <span className="number" dangerouslySetInnerHTML={{__html: post.acf.utgave}} />
        </BackgroundImage>
            <h1 dangerouslySetInnerHTML={{__html: post.title.rendered}} />
            {post.acf.publiseringsdato &&
            <span className="time" dangerouslySetInnerHTML={{__html: post.acf.publiseringsdato}} />
            }
            </Link>
            </div>
            
        ))}
           </div>
           <React.Fragment>
      {!posts &&
      <Loader />
      }
      </React.Fragment>
           </React.Fragment>
  );
}

export default Publications;
